@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus {
  outline: none;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  font-family: sans-serif;
}

.loading {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.4;
  }
}
